import React from 'react';
import { withNamespaces } from 'react-i18next';
import cl from 'classnames';
import Application from './Application';
import ListOfFeatures from './ListOfFeatures';
import Review from './Review';
import s from './Application.module.scss';
import Link from '../Link';
import Api from '../../utils/api';
import { removeTags } from '../../utils/strings';
import chromeStoreImg from '../../assets/img/applications/chrome-store-img.svg';
import img from '../../assets/img/applications/proxy-extension-picture.png';
import img2 from '../../assets/img/applications/proxy-extension-picture-2x.png';
import img3 from '../../assets/img/applications/proxy-extension-picture-3x.png';
import card1Img from '../../assets/img/wireguardPage/location.svg';
import card2Img from '../../assets/img/wireguardPage/pc.svg';
import card3Img from '../../assets/img/applications/nologs.svg';
import card4Img from '../../assets/img/wireguardPage/chat.svg';
import card5Img from '../../assets/img/applications/noun-refund-1355939.svg';
import card6Img from '../../assets/img/wireguardPage/perfomance.svg';
import SiteTitle from '../PageHeaderTitle';

export const ApplicationChromeExtension = withNamespaces()(({ t, gatsbyUrl, pythonUrl, i18n, brand }) => {
  const api = new Api({ gatsbyUrl, pythonUrl, i18n });

  return (
    <Application
      h1={t('page_applications:h1_extension')}
      text={t('page_applications:text_1_extension')}
      list={t('page_applications:list_extension')}
      img={{ img, img2, img3 }}
      img_alt={t('page_applications:image_alt_extension')}
      img_title={`${brand.name} ${t('page_applications:image_alt_extension')}`}
      maxHeight='489px'
    >
      <SiteTitle brandName={brand.name} title={removeTags(t('page_applications:h1_extension'))} />
      <div className={s.buttons}>
        <Link className={cl(s.button, s.button_store, s.button_accent)} to={api.applicationChromeStoreUrl()}>
          <img
            src={chromeStoreImg}
            alt={t('page_applications:h1_extension')}
          />
        </Link>
      </div>
    </Application>
  );
});

export const ListOfFeaturesExtension = withNamespaces()(({ t }) => (
  <ListOfFeatures cards={[
    { title: t('page_applications:card_5_title'), text: t('page_applications:card_14_text'), imgSrc: card1Img },
    { title: t('page_applications:card_13_title'), text: t('page_applications:card_13_text'), imgSrc: card2Img },
    { title: t('page_applications:card_1_title'), text: t('page_applications:card_1_text'), imgSrc: card3Img },
    { title: t('page_applications:card_14_title'), text: t('page_applications:card_6_text_1'), imgSrc: card4Img },
    { title: t('page_applications:card_15_title'), text: t('page_applications:card_15_text'), imgSrc: card5Img },
    { title: t('page_applications:card_16_title'), text: t('page_applications:card_16_text'), imgSrc: card6Img },
  ]}
  />
));

export const ReviewExtension = withNamespaces()(({ t }) => (
  <Review
    text={t('page_applications:review_1')}
    author={t('page_applications:anonymous')}
  />
));
